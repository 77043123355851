import React from 'react';
import Dashboard from './Components/Dashboard/dashboard';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { SnackbarProvider } from 'notistack';

class App extends React.Component {
  render(){
    return (
      <ThemeProvider theme={theme} >
        <div style={{ height:'100vh', display:"flex", justifyContent: "center"}}>
          <div style={{width:"100%", maxWidth: '1280px', height: '100%'}}>
          <SnackbarProvider maxSnack={3}>
              <Dashboard />
          </SnackbarProvider>
          </div>
        </div>
      </ThemeProvider>
      
    )
  }
}

export default App;
