import { createTheme } from '@material-ui/core/styles';
// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "#38b4fd"
    }
  },
});
export default theme;