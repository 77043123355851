import React, {useState} from 'react';
import {Grid, Typography, Divider, Chip, Button, TextField, Card, CardActions, CardContent, CardHeader} from '@material-ui/core';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

function Content(){
    const [amount, setAmount] = useState([""]);
    const [link, setLink] = useState([""]);
    const [password, setPassword] = useState([""]);
    const {enqueueSnackbar} = useSnackbar();

    const handleSuccess = (msg) => {
        enqueueSnackbar(msg, {variant: 'success'});
    }

    const handleFailure = (msg) => {
        enqueueSnackbar(msg, {variant: 'error'});
    }

    const submitOrder = () => {
        Axios.post('/api/powerlikes', {
            amount: amount,
            postLink: link,
            password: password
        }, {withCredentials: true}).then((res)=>{
            if(res.data.error) return handleFailure(res.data.error);
            handleSuccess(res.data.message);
        }).catch((err)=>{
            handleFailure('Fatal error.');
            console.warn(err);
        })
    }

    return(
        <Grid container style={{width:'100%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
            <Grid item>
                <Card style={{width: '390px', textAlign: 'center'}}>
                    <CardHeader
                        title="Make an order"
                    />
                    <CardContent>
                        <TextField fullWidth onChange={(e)=>setAmount(e.target.value)} required label="Amount" />
                        <TextField fullWidth onChange={(e)=>setLink(e.target.value)} required label="Link" />
                        <TextField fullWidth onChange={(e)=>setPassword(e.target.value)} required label="Password" type="password"  />
                    </CardContent>
                    <CardActions>
                        <Button onClick={submitOrder} size="small" fullWidth variant="contained" color="secondary">SUBMIT</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>

    )
}

export default function Dashboard(){
    return (
        <Content />
    )
}